import * as React from "react"
import { Row } from "react-bootstrap"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import RecentBlogPosts from "../components/RecentBlogPosts"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Home"
      description="Deglutino knows how to make progress when the feeling of being stuck is staring you down. This is how."
    />
    <Row className="pt-5">
      <div className="h-100 p-5 text-white bg-dark rounded-3">
        <h2>Building Software Is Not Easy</h2>
        <p>
          We know this because, we are software engineers ourselves. Rather than let the technology get the better of us, we choose to write about it. Teaching others what we have learned on the job.
        </p>
      </div>
    </Row>
    <RecentBlogPosts />
  </Layout>
)

export default IndexPage
