import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import BlogListItem from '../BlogListItem'

const RecentBlogPosts = () => {

    const data = useStaticQuery(graphql`
    query recentPosts {
        allMdx(
          filter: { frontmatter: { isPreview: { eq : false }}}
          sort: { order: DESC, fields: [frontmatter___date] }, limit: 3) {
          edges {
            node {
              id
              excerpt(pruneLength: 250)
              slug
              frontmatter {
                date(formatString: "YYYY-MM-DD")
                title
                imagePath
              }
            }
          }
        }
      }
  `)

    return (
        <>
        <Row className={`py-3`}>
            <h3 className="display-6 fw-bold text-center">Recent Activity</h3>
            {
                data.allMdx.edges.map((edge, idx) => (
                <BlogListItem idx={idx} blogData={edge.node}/>
                ))
            }
        </Row>
        <Row className={`justify-content-evenly`}>
            <Col sm={3} className={`mx-auto pb-4`}>
               <Link className="btn btn-primary w-100" to="/blog">See More Activity</Link>
            </Col>
        </Row>
        </>
    )}

export default RecentBlogPosts